<template>
  <div class="myorder">
    <van-nav-bar
      title="我的订单"
      left-arrow
      @click-left="onClickLeft"
    />
    <main>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多"
        @load="onLoad"
      >
        <div class="orderbox" v-for="(item, index) in orderLists" :key="index">
          <div class="ordercontet">
            <van-row gutter="20">
              <van-col span="8">订单编号:</van-col>
              <van-col span="8">{{item.orderNo}}</van-col>
            </van-row>
            <van-row gutter="20">
              <van-col span="8">创建时间:</van-col>
              <van-col span="16">{{item.createdAt}}</van-col>
            </van-row>
            <van-row gutter="20">
              <van-col span="8">交易状态:</van-col>
              <van-col span="16" :style="item.status == 1 ? 'color: #000' : 'color: #00992A'">
                {{ item.status == 1 ? '待支付' : '支付成功' }}
              </van-col>
            </van-row>
            <van-row gutter="20">
              <van-col span="8">商品:</van-col>
              <van-col span="16">{{item.date}}天</van-col>
            </van-row>
            <van-row gutter="20">
              <van-col span="8">金额:</van-col>
              <van-col span="16">{{item.money * 100 / 100}}元</van-col>
            </van-row>
          </div>
        </div>
      </van-list>
    </main>
  </div>
</template>
<script>
import { orderList } from '@/api'
export default {
  data() {
    return {
      orderLists: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      finished: false
    }
  },
  methods: {
    // 初始数据
    initData() {
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      orderList(data).then(res => {
        this.total = res.data.total;
        if(this.total <= this.pageSize){
          this.orderLists = res.data.list;
        }else{
          this.pageNum++;
          let arr = res.data.list;
          this.orderLists = this.orderLists.concat(arr);
        }
        this.loading = false;
        // 数据全部加载完成
        if (this.orderLists.length >= this.total) {
          this.finished = true;//结束，显示我也是有底线的
        }
      })
    },
    // 上拉加载更多
    onLoad() {
      this.initData()
    },
    onClickLeft() {
      this.$router.back(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.myorder{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  main{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    .orderbox{
      width: 351px;
      height: 172px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 0px rgba(160, 160, 160, 0.4);
      border-radius: 4px;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:nth-child(1) {
        margin-top: 21px;
      }
      .ordercontet{
        width: 303px;
        height: 140px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 28px;
      }
    }
  }
}
</style>
